import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';

import { ContentLayout, QuickBottomNavigationWrapper } from '../../../components';
import Layout from '../../../components/layout';
import { GalleryNav, MediaPackButton } from '../../../components/Pages/Gallery';
import SEO from '../../../components/seo';
import styles from '../../../stylesheets/pages/page.module.scss';
import { GALLERY_SUBSECTIONS, QUICK_NAVIGATION_GALLERY, getLocalizedDataFromContentfulEdges } from '../../../utils';

const Factsheet = () => {
	const intl = useIntl();
	const { allContentfulGallery } = useStaticQuery(graphql`
		{
			allContentfulGallery(filter: { path: { eq: "factsheet" } }) {
				edges {
					node {
						node_locale
						name
						assets {
							... on ContentfulGalleryDocument {
								file {
									file {
										url
									}
								}
							}
						}
						packageDescription
					}
				}
			}
		}
	`);
	const gallerySection = getLocalizedDataFromContentfulEdges(allContentfulGallery.edges, intl.locale)[0];
	const { packageDescription, assets } = gallerySection.node;
	const { url } = assets[0]?.file?.file;

	return (
		<Layout>
			<SEO title="Newsroom - Factsheet" />
			<QuickBottomNavigationWrapper optionalDataArray={QUICK_NAVIGATION_GALLERY}>
				<ContentLayout>
					<div className={styles.pageContent}>
						<GalleryNav active={GALLERY_SUBSECTIONS.factsheet} isGallerySubpage />
						{packageDescription && url && <MediaPackButton name={packageDescription} url={`https:${url}`} type="pdf" />}
					</div>
				</ContentLayout>
			</QuickBottomNavigationWrapper>
		</Layout>
	);
};

export default Factsheet;
